$SurfaceIndependence40: #2d2d47;
$SurfaceCultured10: #f4f4f5;
$SurfacePlatinum20: #eaeaec;
$SurfaceRomanSilver30: #8f8e99;
$SurfaceRaisinBlack50: #1a1a29;
$SurfaceRichBlack60: #0a0a0b;
$AccentNapplesYellow: #f5d956;
$AccentMediumChampagne: #faecab;
$CaseError: #ff7061;
$CaseCorrect: #57cd9c;
$SurfaceWhite: #ffffff;
$AccentJonquil: #f2ca18;
$AccentCornsilk: #fff8d9;
$SurfaceSpaceCadet50: #23233b;
$BorderGray50: #f7f8fa;
$GrayF2: #F2F2F2;
$Gray96: #969696;
$Black24: #242424;

$DarkBlue: #1A1A29;

$colors: (
  'SurfaceIndependence40' $SurfaceIndependence40,
  'SurfaceCultured10' $SurfaceCultured10,
  'SurfacePlatinum20' $SurfacePlatinum20,
  'SurfaceRomanSilver30' $SurfaceRomanSilver30,
  'SurfaceRaisinBlack50' $SurfaceRaisinBlack50,
  'SurfaceRichBlack60' $SurfaceRichBlack60,
  'AccentNapplesYellow' $AccentNapplesYellow,
  'AccentMediumChampagne' $AccentMediumChampagne,
  'CaseError' $CaseError,
  'CaseCorrect' $CaseCorrect,
  'SurfaceWhite' $SurfaceWhite,
  'AccentJonquil' $AccentJonquil,
  'AccentCornsilk' $AccentCornsilk,
  'SurfaceSpaceCadet50' $SurfaceSpaceCadet50,
  'BorderGray50' $BorderGray50,
  'GrayF2' $GrayF2,
  'Gray96' $Gray96,
  'Black24' $Black24,
  'DarkBlue' $DarkBlue
);
