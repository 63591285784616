.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-inline-block {
  display: inline-block;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.c-pointer {
  cursor: pointer;
}

.white-space-nowrap {
  white-space: nowrap;
}

.last-mb-0 {
  &:last-child {
    margin-bottom: 0;
  }
}

.first-mb-0 {
  &:first-child {
    margin-bottom: 0;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.skeleton-block {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}
